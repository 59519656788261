import { useOrganisationList } from "@inrange/building-manager-api-client";
import {
  PartialSiteAllowStringValues,
  SiteAllowStringValues,
} from "@inrange/building-manager-api-client/models-site";
import {
  SiteCalculationsContext,
  useContextTS,
} from "@inrange/shared-components";
import { useEffect } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

const Tariff = ({
  site,
  setSite,
  isBulkEdit,
}: {
  site: SiteAllowStringValues;
  setSite: (site: PartialSiteAllowStringValues) => void;
  isBulkEdit?: boolean;
}) => {
  const { errors, siteCalculations, setClientsideValidations } = useContextTS(
    SiteCalculationsContext
  );
  const { fetchOrganisations } = useOrganisationList();
  const orgsById = (fetchOrganisations.data?.organisations || []).reduce(
    (acc, org) => {
      acc[org.id] = org;
      return acc;
    },
    {}
  );
  const maxTenantTariff = (site.siteOwnerships || [])
    .filter(
      (so) =>
        (so.ownership === "tenant" || so.ownership === "ownerOccupier") &&
        orgsById[so.orgID] !== undefined &&
        orgsById[so.orgID].maxTenantTariff !== undefined
    )
    .map((so) => orgsById[so.orgID].maxTenantTariff)
    .reduce((acc, maxTenantTariff) => {
      if (acc === undefined) {
        return maxTenantTariff;
      }
      // If there are multiple maxTenantTariff values, return the lowest one
      return maxTenantTariff < acc ? maxTenantTariff : acc;
    }, undefined);
  const isTenantTariffOutOfBounds =
    maxTenantTariff &&
    parseFloat(site.tenantTariff as string) > maxTenantTariff;
  const maxNetworkImportTariff = (site.siteOwnerships || [])
    .filter(
      (so) =>
        (so.ownership === "tenant" || so.ownership === "ownerOccupier") &&
        orgsById[so.orgID] !== undefined &&
        orgsById[so.orgID].maxNetworkImportTariff !== undefined
    )
    .map((so) => orgsById[so.orgID].maxNetworkImportTariff)
    .reduce((acc, maxNetworkImportTariff) => {
      if (acc === undefined) {
        return maxNetworkImportTariff;
      }
      // If there are multiple maxNetworkImportTariff values, return the lowest one
      return maxNetworkImportTariff < acc ? maxNetworkImportTariff : acc;
    }, undefined);
  const isNetworkImportTariffOutOfBounds =
    maxNetworkImportTariff &&
    parseFloat(site.networkImportTariff as string) > maxNetworkImportTariff;
  useEffect(() => {
    setClientsideValidations((prev) => ({
      ...prev,
      isTenantTariffOutOfBounds: isTenantTariffOutOfBounds,
      isNetworkImportTariffOutOfBounds: isNetworkImportTariffOutOfBounds,
    }));
  }, [
    isNetworkImportTariffOutOfBounds,
    isTenantTariffOutOfBounds,
    setClientsideValidations,
  ]);
  return (
    <Card body className="mt-2">
      <Form.Label>
        <strong>Tariff</strong>
      </Form.Label>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="marketTariff">
            <Form.Label>Market tariff</Form.Label>
            <Form.Control
              type="text"
              value={site.marketTariff}
              onChange={(e) => setSite({ marketTariff: e.target.value })}
              isInvalid={!!errors["marketTariff"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["marketTariff"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="tenantTariff">
            <Form.Label>Tenant tariff</Form.Label>
            <Form.Control
              type="text"
              value={
                parseFloat(site.installedCapacity as string) === 0
                  ? ""
                  : site.tenantTariff
              }
              onChange={(e) => setSite({ tenantTariff: e.target.value })}
              isInvalid={!!errors["tenantTariff"] || isTenantTariffOutOfBounds}
              data-testid={"tenant-tariff"}
              disabled={parseFloat(site.installedCapacity as string) === 0}
            />
            <Form.Control.Feedback type="invalid">
              {errors["tenantTariff"] ||
                (isTenantTariffOutOfBounds
                  ? `Max tenant tariff is ${maxTenantTariff}`
                  : undefined)}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="networkImportTariff">
            <Form.Label>Network import tariff</Form.Label>
            <Form.Control
              type="text"
              value={site.networkImportTariff}
              onChange={(e) => setSite({ networkImportTariff: e.target.value })}
              isInvalid={
                !!errors["networkImportTariff"] ||
                isNetworkImportTariffOutOfBounds
              }
            />
            <Form.Control.Feedback type="invalid">
              {errors["networkImportTariff"] ||
                (isNetworkImportTariffOutOfBounds
                  ? `Max network import tariff is ${maxNetworkImportTariff}`
                  : undefined)}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="spillExportTariff">
            <Form.Label>Spill export tariff</Form.Label>
            <Form.Control
              type="text"
              value={site.inrangeExportTariff}
              onChange={(e) => setSite({ inrangeExportTariff: e.target.value })}
              isInvalid={!!errors["inrangeExportTariff"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["inrangeExportTariff"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      {!isBulkEdit && (
        <Row>
          <Col sm={6}>
            <Form.Group className="mb-3" controlId="blendedExportTariff">
              <Form.Label>Blended export tariff</Form.Label>
              <Form.Control
                type="text"
                value={siteCalculations?.blendedExportTariff || 0}
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="leaseRevenueShare">
            <Form.Label>Lease revenue share</Form.Label>
            <Form.Control
              type="text"
              value={site.landlordLeaseRevenueShare}
              onChange={(e) =>
                setSite({
                  landlordLeaseRevenueShare: e.target.value,
                })
              }
              isInvalid={!!errors["landlordLeaseRevenueShare"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["landlordLeaseRevenueShare"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="licenseRevenueShare">
            <Form.Label>License revenue share</Form.Label>
            <Form.Control
              type="text"
              value={site.landlordLicenseRevenueShare}
              onChange={(e) =>
                setSite({
                  landlordLicenseRevenueShare: e.target.value,
                })
              }
              isInvalid={!!errors["landlordLicenseRevenueShare"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["landlordLicenseRevenueShare"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="ooLicenseRevenueShare">
            <Form.Label>Owner occupier license revenue share</Form.Label>
            <Form.Control
              type="text"
              value={site.ownerOccupierLicenseRevenueShare}
              onChange={(e) =>
                setSite({
                  ownerOccupierLicenseRevenueShare: e.target.value,
                })
              }
              isInvalid={!!errors["ownerOccupierLicenseRevenueShare"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["ownerOccupierLicenseRevenueShare"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="ooLicenseSaasTariff">
            <Form.Label>OwnOcc license saas tariff rate</Form.Label>
            <Form.Control
              type="text"
              value={site.ownerOccupierLicenseInRangeSaasTariffRate}
              onChange={(e) =>
                setSite({
                  ownerOccupierLicenseInRangeSaasTariffRate: e.target.value,
                })
              }
              isInvalid={!!errors["ownerOccupierLicenseInRangeSaasTariffRate"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["ownerOccupierLicenseInRangeSaasTariffRate"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Card>
  );
};

export default Tariff;
