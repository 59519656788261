import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useQuery } from "react-query";
import getSiteSdmList from "../http/getSiteSdmList";
import { SdmOffer } from "../models/site";

interface UseSiteSdmProps {
  siteId?: string;
  getAllResults?: boolean;
  onlyLinkedOrgs?: boolean;
  userOrgId: string;
}

const useSiteSdm = ({
  siteId,
  getAllResults,
  onlyLinkedOrgs = false,
  userOrgId,
}: UseSiteSdmProps) => {
  const { getToken } = useKindeAuth();

  // Note: userOrgId is a required parameter for the customer app
  // usage of this API. It is not required for the admin app.

  const fetchSiteSdmList = useQuery<{
    result: {
      buy?: SdmOffer;
      sell?: SdmOffer;
    };
  }>(
    ["site", siteId, "sdmList"],
    async () => {
      return getSiteSdmList(
        await getToken(),
        siteId,
        getAllResults,
        onlyLinkedOrgs,
        userOrgId
      );
    },
    { enabled: !!siteId }
  );

  const bestBuyOffer = fetchSiteSdmList.data?.result?.buy;
  const bestSellOffer = fetchSiteSdmList.data?.result?.sell;

  return {
    fetchSiteSdmList,
    bestBuyOffer,
    bestSellOffer,
  };
};

export default useSiteSdm;
