import { Card, Col, Form, Row } from "react-bootstrap";
import { PPA_TYPE_LABELS } from "./enums";
import { epochSecondsToDate } from "./utils";

import {
  PartialSiteAllowStringValues,
  SiteAllowStringValues,
} from "@inrange/building-manager-api-client/models-site";
import {
  SiteCalculationsContext,
  useContextTS,
} from "@inrange/shared-components";
import { BorderedCheckbox } from "@inrange/theme-components";

const DealTerms = ({
  site,
  setSite,
}: {
  site: SiteAllowStringValues;
  setSite: (site: PartialSiteAllowStringValues) => void;
}) => {
  const { errors, siteCalculations } = useContextTS(SiteCalculationsContext);
  return (
    <Card body className="mt-2">
      <Form.Label>
        <strong>Deal and contract terms</strong>
      </Form.Label>
      <Row>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="ppaLength">
            <Form.Label>On-site PPA length (years)</Form.Label>
            <Form.Control
              type="text"
              value={
                parseFloat(site.installedCapacity as string) === 0
                  ? ""
                  : site.ppaLength
              }
              onChange={(e) => setSite({ ppaLength: e.target.value })}
              isInvalid={!!errors.ppaLength}
              disabled={parseFloat(site.installedCapacity as string) === 0}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ppaLength}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Label>On-site PPA type</Form.Label>
          <Form
            style={{
              display: "flex",
              fontSize: 12,
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            {Object.entries(PPA_TYPE_LABELS).map(([key, label]) => (
              <BorderedCheckbox
                key={`onsite-${key}`}
                dataTestId={`onsite-${key}`}
                formName="ppa_type"
                value={key}
                label={label}
                selectedOption={site.ppaType}
                setSelectedOption={(value) => setSite({ ppaType: value })}
                disabled={parseFloat(site.installedCapacity as string) === 0}
              />
            ))}
          </Form>
        </Col>
        {site.ppaType === "indexed" && (
          <Col sm={4}>
            <Form.Group className="mb-3" controlId="ppaIndex">
              <Form.Label>On-site PPA index (%)</Form.Label>
              <Form.Control
                type="text"
                value={
                  parseFloat(site.installedCapacity as string) === 0
                    ? ""
                    : site.ppaIndex
                }
                onChange={(e) => setSite({ ppaIndex: e.target.value })}
                isInvalid={!!errors.ppaIndex}
                disabled={parseFloat(site.installedCapacity as string) === 0}
              />
              <Form.Control.Feedback type="invalid">
                {errors.ppaIndex}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="exportPPALength">
            <Form.Label>Export PPA length (years)</Form.Label>
            <Form.Control
              type="text"
              value={
                parseFloat(site.installedCapacity as string) === 0
                  ? ""
                  : site.exportPPALength
              }
              onChange={(e) => setSite({ exportPPALength: e.target.value })}
              isInvalid={!!errors.exportPPALength}
              disabled={parseFloat(site.installedCapacity as string) === 0}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ppaLength}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Label>Export PPA type</Form.Label>
          <Form
            style={{
              display: "flex",
              fontSize: 12,
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            {Object.entries(PPA_TYPE_LABELS).map(([key, label]) => (
              <BorderedCheckbox
                key={`export-${key}`}
                dataTestId={`export-${key}`}
                formName="export_ppa_type"
                value={key}
                label={label}
                selectedOption={site.exportPPAType}
                setSelectedOption={(value) => setSite({ exportPPAType: value })}
                disabled={parseFloat(site.installedCapacity as string) === 0}
              />
            ))}
          </Form>
        </Col>
        {site.exportPPAType === "indexed" && (
          <Col sm={4}>
            <Form.Group className="mb-3" controlId="exportPPAIndex">
              <Form.Label>Export PPA index (%)</Form.Label>
              <Form.Control
                type="text"
                value={
                  parseFloat(site.installedCapacity as string) === 0
                    ? ""
                    : site.exportPPAIndex
                }
                onChange={(e) => setSite({ exportPPAIndex: e.target.value })}
                isInvalid={!!errors.exportPPAIndex}
                disabled={parseFloat(site.installedCapacity as string) === 0}
              />
              <Form.Control.Feedback type="invalid">
                {errors.exportPPAIndex}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row>
        <Col sm={4}>
          <Form.Group
            className={"mb-3"}
            controlId="leaseLengthDateEpochSeconds"
          >
            <Form.Label>Lease length</Form.Label>
            <Form.Control
              style={{
                color: site.leaseLengthDateEpochSeconds ? "black" : "grey",
              }}
              type="date"
              onClick={(e) => {
                const element = e.target;
                if ("showPicker" in HTMLInputElement.prototype) {
                  // showPicker() is supported. Chrome 99+ and Safari 16+
                  // see https://caniuse.com/?search=showPicker
                  (element as any).showPicker();
                }
              }}
              value={epochSecondsToDate(site.leaseLengthDateEpochSeconds)}
              onChange={(e) => {
                const date = new Date(e.target.value);
                const invalidDate = isNaN(date as unknown as number);
                const epochSeconds = invalidDate
                  ? undefined
                  : date.getTime() / 1000; // if the date was cleared, set to null, otherwise getTime returns milliseconds, but we want seconds
                setSite({
                  leaseLengthDateEpochSeconds: epochSeconds,
                });
              }}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3">
            <Form.Label>Lease length (months)</Form.Label>
            <Form.Control
              disabled
              value={siteCalculations.leaseLengthMonths ?? ""}
            />
          </Form.Group>
        </Col>
      </Row>
    </Card>
  );
};

export default DealTerms;
