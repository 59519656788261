import {
  useOrganisationList,
  useSiteList,
} from "@inrange/building-manager-api-client";
import { sortBy } from "@inrange/calculations/utils.ts";
import { useState } from "react";
import { Container } from "react-bootstrap";
import Select from "react-select";
import ListTable from "../ListTable";
import Loading from "../Loading";
import { STATUS_OPTIONS } from "./operationalStatusUtils";
import SiteListGeo from "./siteSections/SiteGeo/SiteListGeo";
import { SITE_ORG_LABELS } from "./siteSections/enums";

const testMode = !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE;

const capitalise = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const columns = [
  // This is a column definition array that defines the
  // shape of our table.
  // More info on this column def array can be found at
  // https://tanstack.com/table/v8/docs/guide/column-defs
  {
    header: "Site name",
    id: "site",
    accessorFn: (row) => `${row.name} ${row.id}`,
    cell: (info) => (
      <div>
        <div>
          <a href={`/site/${info.row.original.id}/edit`}>
            {info.row.original.name || "No name"}
          </a>
          <br />
          {info.row.original.isScenario
            ? "(Excluded from customer-facing SDM)"
            : ""}
        </div>
        <div style={{ fontSize: 10 }}>
          {testMode ? "fixed-id-for-test" : info.row.original.id}
        </div>
      </div>
    ),
    filterFn: (row, _, filterValues) => {
      if (!filterValues) return true;
      return filterValues.includes(row.original.id);
    },
    size: 200,
  },
  {
    header: "Site info",
    id: "siteInfo",
    accessorFn: (row) => {
      if (!row.commerciallyOperationalDateEpochSeconds) {
        return {
          commerciallyOperationalDate: undefined,
          status: row.operationalStatus,
          siteOwnerships: row.siteOwnerships,
          investmentModel: row.investmentModel,
        };
      }
      const date = new Date(row.commerciallyOperationalDateEpochSeconds * 1000);
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const dateFormat = new Intl.DateTimeFormat("en-UK", options);
      return {
        commerciallyOperationalDate: dateFormat.format(date),
        status: row.operationalStatus,
        siteOwnerships: row.siteOwnerships,
        investmentModel: row.investmentModel,
      };
    },
    cell: (info) => {
      const values = info.getValue();
      return (
        <>
          {values.siteOwnerships
            .sort(sortBy((siteOrg) => `${siteOrg.ownership}:${siteOrg.name}`))
            .map((siteOrg) => (
              <div key={siteOrg.orgID} style={{ fontSize: 13 }}>
                {SITE_ORG_LABELS[siteOrg.ownership]}:{" "}
                <strong>{siteOrg.name}</strong>
              </div>
            ))}
          <div style={{ fontSize: 13 }}>
            Investment Model:{" "}
            <strong>{capitalise(values.investmentModel)}</strong>
          </div>
          <div style={{ fontSize: 13 }}>
            Operational Status: <strong>{STATUS_OPTIONS[values.status]}</strong>
          </div>
          {values.commerciallyOperationalDate && (
            <div style={{ fontSize: 13 }}>
              CO Date: <strong>{values.commerciallyOperationalDate}</strong>
            </div>
          )}
        </>
      );
    },
    filterFn: (row, _, filterValues) => {
      if (!filterValues.length) return true;
      return row.original.siteOwnerships.some((ownership) =>
        filterValues.includes(ownership.orgID)
      );
    },
    sortDescFirst: false,
    size: 200,
  },
  {
    header: "Address",
    id: "address",
    accessorKey: "address",
    sortDescFirst: true,
    cell: (info) => info.getValue(),
    size: 200,
  },
  {
    header: "Last modified date",
    id: "lastModifiedDate",
    accessorFn: (row) => {
      const date = new Date(row.updatedAt * 1000);
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        timeZone: "UTC",
        timeZoneName: "short",
      };
      const dateFormat = new Intl.DateTimeFormat("en-UK", options);
      return dateFormat.format(date);
    },
    sortingFn: "sortFnUpdated",
    sortDescFirst: true,
    cell: (info) => (testMode ? "fixed date for test" : info.getValue()),
    size: 200,
  },
  {
    header: "Created date",
    id: "createdDate",
    accessorFn: (row) => {
      const date = new Date(row.createdAt * 1000);
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        timeZone: "UTC",
        timeZoneName: "short",
      };
      const dateFormat = new Intl.DateTimeFormat("en-UK", options);
      return dateFormat.format(date);
    },
    sortingFn: "sortFnCreated",
    sortDescFirst: true,
    cell: (info) => (testMode ? "fixed date for test" : info.getValue()),
    size: 200,
  },
  {
    header: "Notes",
    id: "notes",
    accessorKey: "notes",
    sortDescFirst: true,
    cell: (info) => info.getValue(),
    size: 200,
  },
];

const SiteList = () => {
  document.title = "Site List";

  const params = new URL(document.location).searchParams;
  const testOrgId = params.get("testOrgId");

  const [selectedOrgs, setSelectedOrgs] = useState(
    testOrgId ? [testOrgId] : []
  );
  const [siteIdsInRegion, setSiteIdsInRegion] = useState(undefined);
  const [sitesInViewLoading, setSitesInViewLoading] = useState(true);

  const { fetchSites } = useSiteList();
  const { fetchOrganisations } = useOrganisationList();

  if (fetchSites.isLoading || fetchOrganisations.isLoading)
    return <Loading label="Loading sites data..." />;

  if (fetchSites.isError || fetchOrganisations.isError) {
    return <div>Error loading the sites.</div>;
  }

  let sites = fetchSites.data?.sites;

  const organisations = fetchOrganisations.data?.organisations.sort((a, b) => {
    a = a.name.toUpperCase();
    b = b.name.toUpperCase();
    return a < b ? -1 : a > b ? 1 : 0;
  });

  const orgSelectOptions = organisations.map((org) => ({
    value: org.id,
    label: org.name,
  }));

  const orgFilterSelect = (
    <Select
      placeholder="Filter by organisation..."
      onChange={(values) =>
        setSelectedOrgs(values.map((value) => value["value"]))
      }
      options={orgSelectOptions}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          border: "var(--bs-border-width) solid var(--bs-border-color)",
          borderRadius: "var(--bs-border-radius)",
          color: "var(--bs-body-color)",
        }),
      }}
      isMulti
      key="orgFilterSelect"
    />
  );

  return (
    <>
      <Container className="mt-4" style={{ minHeight: "90vh" }}>
        <SiteListGeo
          sites={sites}
          selectedOrgs={selectedOrgs}
          setSiteIdsInRegion={setSiteIdsInRegion}
          setSitesInViewLoading={setSitesInViewLoading}
        />
        <ListTable
          columns={columns}
          loading={sitesInViewLoading}
          data={sites}
          title={"Sites"}
          columnFilters={[
            { id: "site", value: siteIdsInRegion },
            { id: "siteInfo", value: selectedOrgs },
          ]}
          customFilterElements={[orgFilterSelect]}
          sortingFns={{
            sortFnCreated: sortBy((row) => row.original.createdAt),
            sortFnUpdated: sortBy((row) => row.original.updatedAt),
          }}
        />
      </Container>
    </>
  );
};

export default SiteList;
