export const ACTIVITY_LOG_MAP = {
  siteOwnerships: "Site Ownerships",
  siteMPANs: "Site MPANs",
  buildings: "Buildings",
  totalBuildingArea: "Total Building Area",
  updatedByName: "Updated By Name",
  name: "Name",
  address: "Address",
  notes: "Notes",
  commerciallyOperationalDateEpochSeconds: "Commerially Operational Date",
  leaseLengthDateEpochSeconds: "Lease Length (Date)",
  postcode: "Postcode",
  latitude: "Latitude",
  longitude: "Longitude",
  annualGenerationLoss: "Annual Generation Loss",
  roofUtilization: "Roof Utilization",
  panelCostPerSqm: "Panel Cost Per SqM",
  gridConnectionCost: "Grid Connection Cost",
  marketTariff: "Market Tariff",
  tenantTariff: "Tenant Tariff",
  gridExportTariff: "Grid Export Tariff",
  inrangeExportTariff: "InRange Export Tariff",
  landlordLeaseRevenueShare: "Landlord Lease Revenue Share",
  landlordLicenseRevenueShare: "Landlord License Revenue Share",
  ownerOccupierLicenseRevenueShare: "Owner Occupier License Revenue Share",
  pvDesignSystemLastUpdatedAt: "PV Design System Last Updated At",
  pvDesignSystemLastUpdatedBy: "PV Design System Last Updated By",
  ownerOccupierLicenseInRangeSaasTariffRate:
    "Owner Occupier InRange SaaS Tariff Rate",
  demandCoefficientKWhPerM2: "Demand Coefficient (kWh/m²)",
  buildingProfile: "Building Profile",
  tenantEnergyShare: "Tenant Energy Share",
  investmentModel: "Investment Model",
  ghi: "GHI",
  hubspotUpdateTime: "Hubspot Update Time",
  hubspotRecordID: "HubSpot Record ID",
  installedCapacity: "Installed Capacity",
  tenantAnnualDemandKwh: "Demand (kWh)",
  demand: "Demand (kWh)",
  behindMeter: "Behind Meter (kWh)",
  curtailed: "Curtailed (kWh)",
  exported: "Exported (kWh)",
  generation: "Generation (kWh)",
  exportLimit: "Export Limit (kVA)",
  gridImport: "Grid Import (kWh)",
  networkImport: "Network Import (kWh)",
  networkExport: "Network Export (kWh)",
  batteryChargeLoss: "Battery Charge Loss (kWh)",
  energyFlowSummerDay: "Energy Flow Summer Day",
  energyFlowWinterDay: "Energy Flow Winter Day",
  hardware: "Hardware Cost",
  installation: "Installation Cost",
  total: "Total Cost",
  initialInvestment: "Total Upfront Capex",
  panel: "Panel Cost",
  mounting: "Mounting Cost",
  inverter: "Inverter Cost",
  electrical: "Electrical Cost",
  bos: "BOS Cost",
  misc: "Misc. Cost",
  insurance: "Insurance Cost (Lifetime)",
  insuranceYearOne: "Insurance Cost (Year 1)",
  maintenance: "Maintenance Cost (Lifetime)",
  maintenanceYearOne: "Maintenance Cost (Year 1)",
  licenseRevenue: "License Revenue",
  onSiteAvoidance: "On Site Avoidance",
  networkAvoidance: "Network Avoidance",
  totalAvoidance: "Total Avoidance",
  generationAvoidance: "Generation Avoidance",
  networkImportTariff: "Network Import Tariff",
  ownerOccupierIRR: "Owner Occupier IRR",
  landlordIRR: "Landlord IRR",
  ownerOccupierPaybackMonths: "Owner Occupier Payback (Months)",
  landlordPaybackMonths: "Landlord Payback (Months)",
  "financialModels.landlord.license.irr": "Landlord IRR",
  "financialModels.landlord.license.paybackMonths": "Landlord Payback (Months)",
  "financialModels.landlord.license.revenue": "Landlord Revenue",
  "financialModels.ownerOccupier.license.irr": "Owner Occupier IRR",
  "financialModels.ownerOccupier.license.paybackMonths":
    "Owner Occupier Payback (Months)",
  "financialModels.ownerOccupier.license.revenue": "Owner Occupier Revenue",
  "financialModels.tenant.savings": "Tenant Savings",
  initialCostPerKWp: "Upfront cost per kWp",
  panelCostPerKWp: "Panel Cost Per kWp",
  mountingCostPerKWp: "Mounting Cost Per kWp",
  inverterCostPerKWp: "Inverter Cost Per kWp",
  electricalCostPerKWp: "Electrical Cost Per kWp",
  bosCostPerKWp: "BOS Cost Per kWp",
  miscCostPerKWp: "Misc. Cost Per kWp",
  enablingWorksCosts: "Enabling Works Cost",
  hardwareCostPerKWp: "Hardware Cost Per kWp",
  installationCostPerKWp: "Installation Cost Per kWp",
  insuranceCostPerKWp: "Insurance Cost Per kWp",
  maintenanceCostPerKWp: "Maintenance Cost Per kWp",
  replacementCapex: "PV Replacement Capex",
  replacementCapexRate: "PV Replacement Capex Rate",
  replacementCapexYear: "PV Replacement Capex Year",
  energyPriceInflationRate: "Energy Price Inflation Rate",
  costInflationRates: "Cost Inflation Rates",
  pvPanelName: "PV Panel Model",
  pvInverterBrand: "PV Inverter Brand",
  batteryPower: "Battery Power (MW)",
  batteryCapacity: "Battery Capacity (MWh)",
  batteryModel: "Battery Model",
  batteryCost: "Battery Cost",
  batteryReplacementYear: "Battery Replacement Capex Year",
  batteryReplacementCapex: "Battery Replacement Capex",
  sdmMatches: "SDM Matches",
  "sdmMatches.volume": "Volume",
  "sdmMatches.buyerId": "Site ID - Buyer",
  "sdmMatches.sellerId": "Site ID - Seller",
  "sdmMatches.tariff": "Tariff",
  ppaContractType: "PPA Contract type",
  ppaType: "PPA Type",
  ppaIndex: "PPA Index",
  ppaLength: "PPA Length",
  exportPPAType: "Export PPA type",
  exportPPAIndex: "Export PPA index",
  exportPPALength: "Export PPA length",
};

export const formatFieldName = (
  lossFactorsByKey: Record<string, any>,
  fieldName: string
) => {
  if (fieldName.startsWith("generationLossFactors.")) {
    const key = fieldName.replace("generationLossFactors.", "");
    return lossFactorsByKey[key]?.label || fieldName;
  } else if (ACTIVITY_LOG_MAP[fieldName]) {
    return ACTIVITY_LOG_MAP[fieldName];
  } else if (fieldName.indexOf(".") !== -1) {
    // For older fields we only have the friendly name mapped to the last part
    // of the changed field name path because we didn't use to include the full path.
    // This code handles falling back to the last part of the path.
    const lastFieldNamePart = fieldName.split(".").slice(-1)[0];
    if (ACTIVITY_LOG_MAP[lastFieldNamePart]) {
      return ACTIVITY_LOG_MAP[lastFieldNamePart];
    }
  }
  // If a friendly name has not been provided, render the raw field path
  return fieldName;
};
