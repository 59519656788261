import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Modal } from "react-bootstrap/";
import { getCurtailmentDetails } from "./dno-utils";

const numString = (value, options) => {
  const { minimumFractionDigits } = options
    ? options
    : { minimumFractionDigits: undefined };
  return new Intl.NumberFormat("en-GB", {
    style: "decimal",
    minimumFractionDigits,
    maximumFractionDigits: 2,
  }).format(value);
};

const DnoNetwork = ({ site, setSite, siteCalculations, errors }) => {
  const [reviewCurtailment, setReviewCurtailment] = useState(false);
  const [showCurtailmentModel, setShowCurtailmentModel] = useState(false);

  const prevCalculationsUpdatedAt = useRef();
  const prevExportLimitWithZeroCurtailment = useRef();

  useEffect(() => {
    const curtailed = siteCalculations.energyFlowAnnual?.curtailed || 0;
    if (prevCalculationsUpdatedAt.current !== siteCalculations.updatedAt) {
      // Site calculations have changed
      prevCalculationsUpdatedAt.current = siteCalculations.updatedAt;
      if (reviewCurtailment && curtailed > 0) {
        // The user has taken an action that requires an optional curtailment review and
        // we've now seen some curtailment, so show the modal
        setShowCurtailmentModel(true);
      } else if (curtailed === 0) {
        // The user took an action requiring a review, and the latest calulation shows
        // zero curtailment so just update prevExportLimitWithZeroCurtailment
        prevExportLimitWithZeroCurtailment.current = site.exportLimit;
      }
      // We've seen a calculation update since the last time we marked a review as required
      // so reset this flag to false here
      setReviewCurtailment(false);
    }
  }, [
    reviewCurtailment,
    siteCalculations.energyFlowAnnual,
    site.exportLimit,
    siteCalculations.updatedAt,
  ]);

  const [curtailment, curtailmentRisk, curtailmentColor] =
    getCurtailmentDetails(site, siteCalculations.energyFlowAnnual);

  return (
    <Card body className="mt-2">
      <Modal
        show={showCurtailmentModel}
        onHide={() => setShowCurtailmentModel(false)}
        centered
      >
        <Modal.Body>
          <span data-testid="export-warning">
            Applying this grid capacity will cause curtailment of{" "}
            {numString(siteCalculations.energyFlowAnnual?.curtailed, {
              minimumFractionDigits: 2,
            })}{" "}
            kWh per year. Are you sure you want to curtail?
          </span>
          <div
            style={{
              display: "flex",
              gap: 24,
              justifyContent: "center",
              marginTop: 8,
            }}
          >
            <Button
              variant="secondary"
              size="sm"
              onClick={() => {
                setShowCurtailmentModel(false);
                setSite({
                  exportLimit: prevExportLimitWithZeroCurtailment.current || "",
                });
              }}
            >
              No, remove it
            </Button>
            <Button
              variant="success"
              size="sm"
              onClick={() => {
                setShowCurtailmentModel(false);
              }}
            >
              Yes, continue
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <h6 style={{ fontWeight: "bold" }}>DNO and network</h6>
      <div style={{ marginTop: "10px" }}>
        <div>
          <strong>DNO Region name:</strong>{" "}
          {siteCalculations.dno?.name || "Data unavailable"}
        </div>
        <div>
          <strong>DNO average intensity:</strong>{" "}
          {siteCalculations.dno?.annualAverageIntensity || "?"} gram/kWh
        </div>
      </div>
      <div style={{ marginTop: "10px" }}>
        <div>
          <strong>Substation name:</strong>{" "}
          {siteCalculations.substationData?.name || "Unknown"}
        </div>
        <div>
          <strong>Substation capacity / generation headroom (kVA):</strong>{" "}
          {site.substationData?.success
            ? site.substationData.generationHeadroomKva
            : "Data unavailable"}
        </div>
        {curtailment === undefined && (
          <div>
            Curtailment risk:{" "}
            <strong style={{ color: curtailmentColor }}>
              {curtailmentRisk}
            </strong>{" "}
          </div>
        )}
      </div>
      <div style={{ marginTop: "10px" }}>
        <Form.Group>
          <Form.Label>
            <div>
              <strong>Applied grid capacity (kVA)</strong>
            </div>
            <div>Defining may cause curtailment.</div>
          </Form.Label>
          <Col sm={4}>
            <Form.Control
              type="text"
              value={
                site.exportLimit === undefined || site.exportLimit === null
                  ? ""
                  : site.exportLimit
              }
              onChange={(e) => {
                if ((siteCalculations.energyFlowAnnual?.curtailed || 0) === 0) {
                  setReviewCurtailment(true);
                }
                setSite({ exportLimit: e.target.value });
              }}
              isInvalid={errors["exportLimit"]}
              data-testid={"exportLimit"}
            />
            <Form.Control.Feedback type="invalid">
              {errors["exportLimit"]}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
      </div>
    </Card>
  );
};

export default DnoNetwork;
