import { useOrganisationList } from "@inrange/building-manager-api-client";
import { Modal, ModalView } from "@inrange/theme-components";
import { useSearchParams } from "react-router-dom";

import {
  DEFAULT_DEMAND_COEFFICIENTS,
  DEFAULT_DEMAND_PROFILES,
  useSite,
} from "@inrange/building-manager-api-client";
import { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import CreateOrg from "../../../org/CreateOrg";
import { SITE_ORG_LABELS } from "../enums";

const AddOwnership = ({
  site,
  setSite,
  ownerships,
  onOwnerAdded,
  setNewSiteName,
  error,
}) => {
  const { fetchOrganisations } = useOrganisationList();

  const refetchOrganisations = async () => {
    await fetchOrganisations.refetch();
  };

  const organisations = (fetchOrganisations.data?.organisations || []).sort(
    (a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    }
  );

  return (
    <AddOwnershipView
      site={site}
      setSite={setSite}
      organisations={organisations}
      ownerships={ownerships}
      onOwnerAdded={onOwnerAdded}
      setNewSiteName={setNewSiteName}
      error={error}
      refetchOrganisations={refetchOrganisations}
    />
  );
};

const AddOwnershipView = ({
  site,
  setSite,
  organisations,
  ownerships,
  onOwnerAdded,
  setNewSiteName,
  error,
  refetchOrganisations,
}) => {
  const [ownershipCount, setOwnershipCount] = useState(1);

  const ownershipSelectors = [...Array(ownershipCount)].map((_, i) => {
    return (
      <OwnershipSelection
        site={site}
        setSite={setSite}
        key={i}
        ownershipIndex={i}
        error={error}
        organisations={organisations}
        ownerships={ownerships}
        onOwnerAdded={onOwnerAdded}
        setNewSiteName={setNewSiteName}
        refetchOrganisations={refetchOrganisations}
      />
    );
  });

  const incrementSelectors = () => {
    setOwnershipCount(ownershipCount + 1);
  };

  return (
    <Row style={{ display: "flex", gap: 10, marginTop: 5, padding: 10 }}>
      <Col>
        <Row>
          <Form.Label>Add site to an organisation</Form.Label>
        </Row>
        {ownershipSelectors}
        <Row>
          <Form.Group className="mb-3">
            <Button variant="success" onClick={incrementSelectors}>
              Another ownership
            </Button>
          </Form.Group>
          <Form.Label style={{ color: "#DC3543" }}>{error}</Form.Label>
        </Row>
      </Col>
    </Row>
  );
};

export default AddOwnership;

const OwnershipSelection = ({
  site,
  setSite,
  ownershipIndex,
  error,
  organisations,
  ownerships,
  onOwnerAdded,
  setNewSiteName,
  refetchOrganisations,
}) => {
  const { fetchSiteValues } = useSite({
    app: "admin",
    enableNonEssentialQueries: true,
  });
  const [searchParams, _setSearchParams] = useSearchParams();
  const urlOrgId = searchParams.get("org");
  const [urlOrgIdConfigured, setUrlOrgIdConfigured] = useState(
    ownershipIndex > 0
  );
  const [orgId, setOrgId] = useState(ownershipIndex === 0 ? urlOrgId : "");
  const [ownershipType, setOwnershipType] = useState("");
  const [showNewOrgModal, setShowNewOrgModal] = useState(false);
  const [showApplyOrgSettingsModal, setShowApplyOrgSettingsModal] =
    useState(false);

  const orgsById = organisations.reduce((acc, org) => {
    acc[org.id] = org;
    return acc;
  }, {});

  const setSiteName = useCallback(
    (newSelectedID, newSelectedName, newSelectedType) => {
      if (!newSelectedType) return;
      const ownershipsArray = Object.values(ownerships);
      ownershipsArray[ownershipIndex] = {
        orgID: newSelectedID,
        name: newSelectedName,
        ownership: newSelectedType,
      };
      setNewSiteName(site.name, ownershipsArray);
    },
    [ownershipIndex, ownerships, setNewSiteName, site.name]
  );

  const onOrgSelected = useCallback(
    (organisation, promptForOrgChanges) => {
      if (promptForOrgChanges && organisation.orgType === "Tenant") {
        if (
          (organisation.buildingProfile &&
            organisation.buildingProfile !== site.buildingProfile) ||
          (organisation.maxTenantTariff &&
            organisation.maxTenantTariff !== site.tenantTariff) ||
          (organisation.maxNetworkImportTariff &&
            organisation.maxNetworkImportTariff !== site.networkImportTariff)
        ) {
          setShowApplyOrgSettingsModal(true);
        }
      }

      setOrgId(organisation.id);
      setOwnershipType(
        organisation.orgType === "Tenant" ? "tenant" : "landlord"
      );

      onOwnerAdded({
        ownershipIndex,
        selectedID: organisation.id,
        selectedType: organisation.orgType === "Tenant" ? "tenant" : "landlord",
        selectedName: organisation.name,
      });
      setSiteName(
        organisation.id,
        organisation.name,
        organisation.orgType === "Tenant" ? "tenant" : "landlord"
      );
    },
    [onOwnerAdded, ownershipIndex, setSiteName, site]
  );

  const applyOrgSettings = useCallback(
    (selectedOrg) => {
      setSite({
        ...(selectedOrg.buildingProfile && {
          buildingProfile: selectedOrg.buildingProfile,
          demandCoefficientKWhPerM2:
            DEFAULT_DEMAND_COEFFICIENTS[selectedOrg.buildingProfile],
          profileShape: DEFAULT_DEMAND_PROFILES[selectedOrg.buildingProfile],
        }),
        ...(selectedOrg.orgType === "Tenant" &&
          selectedOrg.maxTenantTariff && {
            tenantTariff: selectedOrg.maxTenantTariff,
          }),
        ...(selectedOrg.orgType === "Tenant" &&
          selectedOrg.maxNetworkImportTariff && {
            networkImportTariff: selectedOrg.maxNetworkImportTariff,
          }),
        ...(selectedOrg.orgType === "Tenant" &&
          selectedOrg.defaultMarketTariff && {
            marketTariff: selectedOrg.defaultMarketTariff,
          }),
      });
    },
    [setSite]
  );

  useEffect(() => {
    if (
      urlOrgId &&
      organisations &&
      organisations.length > 0 &&
      !urlOrgIdConfigured
    ) {
      const selectedOrg = orgsById[urlOrgId];
      if (selectedOrg) {
        onOrgSelected(selectedOrg, false);
        applyOrgSettings(selectedOrg);
      }
      setUrlOrgIdConfigured(true);
    }
  }, [
    applyOrgSettings,
    onOrgSelected,
    urlOrgId,
    organisations,
    orgsById,
    urlOrgIdConfigured,
    setSite,
  ]);

  const selectedOrg = orgsById[orgId];

  return (
    <Row>
      <Col>
        <Form.Group className="mb-3">
          <Form.Select
            value={orgId ?? ""}
            onChange={(e) => {
              const newSelectedID = e.target.value;
              if (newSelectedID === "new") {
                setShowNewOrgModal(true);
                return;
              }
              onOrgSelected(orgsById[newSelectedID], true);
            }}
            onFocus={refetchOrganisations}
            data-testid={`orgSelect-${ownershipIndex}`}
          >
            <option disabled value="">
              Select Organisation
            </option>
            <option value="new">Create new</option>
            {(organisations || []).map((o) => (
              <option key={o.id} value={o.id}>
                {o.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group className="mb-3">
          <Form.Select
            value={ownershipType ?? ""}
            onChange={(e) => {
              const newSelectedType = e.target.value;
              setOwnershipType(newSelectedType);
              onOwnerAdded({
                ownershipIndex,
                selectedID: orgId,
                selectedType: newSelectedType,
                selectedName: selectedOrg?.name,
              });
              setSiteName(orgId, selectedOrg?.name, newSelectedType);
            }}
            isInvalid={
              error && ["landlord", "ownerOccupier"].includes(ownershipType)
            }
            data-testid={`orgType-${ownershipIndex}`}
          >
            <option disabled value="">
              Select Status
            </option>
            {Object.keys(SITE_ORG_LABELS).map((key) => (
              <option key={key} value={key}>
                {SITE_ORG_LABELS[key]}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        {showNewOrgModal ? (
          <Modal>
            <ModalView
              title="Create new organisation"
              fontWeight="500"
              fontSize="20px"
              width="1000px"
            >
              <CreateOrg
                modal={true}
                handleModalCancel={() => setShowNewOrgModal(false)}
                handleModalSubmit={async (organisation) => {
                  await refetchOrganisations();
                  onOrgSelected(organisation, true);
                  setShowNewOrgModal(false);
                }}
              />
            </ModalView>
          </Modal>
        ) : null}
        {showApplyOrgSettingsModal && (
          <Modal>
            <ModalView
              title={`Apply org settings?`}
              titlePadding="0 0 10px 0"
              fontWeight="500"
              fontSize="20px"
              centerTitle={true}
              width="600px"
            >
              <div>
                <ul>
                  {selectedOrg.buildingProfile &&
                    selectedOrg.buildingProfile !== site.buildingProfile && (
                      <li>
                        Building profile:{" "}
                        {
                          fetchSiteValues.data.options.buildingProfiles[
                            site.buildingProfile
                          ].name
                        }{" "}
                        →{" "}
                        {
                          fetchSiteValues.data.options.buildingProfiles[
                            selectedOrg.buildingProfile
                          ].name
                        }
                      </li>
                    )}
                  {selectedOrg.maxTenantTariff &&
                    selectedOrg.maxTenantTariff !== site.tenantTariff && (
                      <li>
                        Tenant tariff: {site.tenantTariff} →{" "}
                        {selectedOrg.maxTenantTariff}
                      </li>
                    )}
                  {selectedOrg.maxNetworkImportTariff &&
                    selectedOrg.maxNetworkImportTariff !==
                      site.networkImportTariff && (
                      <li>
                        Network import tariff: {site.networkImportTariff} →{" "}
                        {selectedOrg.maxNetworkImportTariff}
                      </li>
                    )}
                </ul>
              </div>
              <Row style={{ marginTop: "10px", justifyContent: "center" }}>
                <Col md={4}>
                  <Button
                    variant="success"
                    onClick={() => {
                      applyOrgSettings(orgsById[orgId]);
                      setShowApplyOrgSettingsModal(false);
                    }}
                    style={{ float: "right" }}
                  >
                    Yes, update
                  </Button>
                </Col>
                <Col md={4}>
                  <Button
                    variant="secondary"
                    onClick={() => setShowApplyOrgSettingsModal(false)}
                  >
                    No, don&apos;t update
                  </Button>
                </Col>
              </Row>
            </ModalView>
          </Modal>
        )}
      </Col>
    </Row>
  );
};
