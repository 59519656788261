import {
  Building,
  Panel,
} from "@inrange/building-manager-api-client/models-site";
import axios from "axios";
import { Feature, LineString, Polygon } from "geojson";
import hash from "object-hash";
import { useQuery, UseQueryResult } from "react-query";

const API_URL = "https://d3ec9b5jthpueq.cloudfront.net";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common.accept = "application/json";

const pvaHostnameMap: Record<string, string> = {
  prod: "https://7rbvbbr755.execute-api.us-east-1.amazonaws.com",
  staging: "https://nh41zqd7q4.execute-api.us-east-1.amazonaws.com",
  "inrt-1466": "https://32buq9xfy3.execute-api.eu-west-1.amazonaws.com", // dev-martin
  "inrt-1468": "https://32buq9xfy3.execute-api.eu-west-1.amazonaws.com", // dev-martin
};

export const getPVAUrl = (): string => {
  const isProduction = import.meta.env.VITE_KINDE_AUTH_ENV === "PROD";
  if (isProduction) {
    return pvaHostnameMap.prod;
  }

  const branchName = import.meta.env?.VITE_BRANCH_NAME;
  const ticketNo = branchName?.split("-").slice(0, 2).join("-");
  return (!!ticketNo && pvaHostnameMap[ticketNo]) || pvaHostnameMap.staging;
};

const queryOptions = (opts: Record<string, unknown>) => {
  return {
    cacheTime: 1000 * 60 * 60, // 1 hour
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    refetchOnWindowFocus: false,
    retry: 3,
    ...opts,
  };
};

// ************************************************
//       Buildings - Geo location of Buildings
// ************************************************
interface Bounds {
  north: number;
  south: number;
  west: number;
  east: number;
}

function getBounds(mapBounds: any): Bounds {
  if (mapBounds === undefined) return { north: 0, south: 0, west: 0, east: 0 };
  return {
    north: mapBounds.getNorth(),
    south: mapBounds.getSouth(),
    west: mapBounds.getWest(),
    east: mapBounds.getEast(),
  };
}

async function getBuildingsByBounds(
  north: number,
  south: number,
  west: number,
  east: number
): Promise<Record<string, any>> {
  const res = await axios.get(
    `${API_URL}/buildings/bounds_postgis/${north}/${south}/${west}/${east}`
  );
  const hashMap: Record<string, any> = {};
  res.data.buildings.forEach((building: any) => {
    const key = hash(JSON.stringify(building.geometry.coordinates));
    hashMap[key] = { ...building, key };
  });
  return hashMap;
}

export const useBuildingsByBounds = (
  mapBounds: any
): UseQueryResult<Record<string, any>> => {
  const { north, south, west, east } = getBounds(mapBounds);

  let mapSizeMeters = 100_000;
  if (mapBounds !== undefined)
    mapSizeMeters =
      mapBounds.getNorthEast().distanceTo(mapBounds.getSouthWest()) || 100_000;

  const data = useQuery(
    ["buildings_by_bounds", north, south, west, east],
    async () => {
      return await getBuildingsByBounds(north, south, west, east);
    },
    queryOptions({ enabled: !!mapBounds && mapSizeMeters < 7000 })
  );
  return data;
};

// ************************************************
// PV System
// ************************************************

async function getPvGeoJson(
  siteId: string,
  panel: { width: number; height: number }
): Promise<any> {
  const pvaUrl = getPVAUrl();
  const url = `${pvaUrl}/pv-geojson?siteid=${siteId}&panelWidth=${panel.width}&panelHeight=${panel.height}`;

  const panelsPointerResponse = await axios.get(url, { timeout: 30_000 });

  const panelsUrl = panelsPointerResponse.data?.panels;
  if (!panelsUrl) {
    return { data: [] };
  }

  return await axios.get(panelsUrl, {
    timeout: 30_000,
  });
}

export const usePvGeoJson = (
  siteId: string | undefined,
  panel: Panel | undefined
): UseQueryResult<any> => {
  return useQuery(
    ["pv_geo_json", siteId, panel],
    async () => {
      return await getPvGeoJson(siteId!, panel!);
    },
    queryOptions({
      // Allow query to be mounted multiple times on the same page without re-fetching
      staleTime: 5 * 60 * 1000,
      enabled: !!siteId && !!panel,
    })
  );
};

export interface SiteData {
  id: string;
  name: string;
  user: string;
}
export interface PvaData {
  status?: {
    num_buildings?: number;
    num_buildings_triggered?: number;
    num_buildings_packed?: number;
  };
  site_metrics?: {
    total_mass: number;
    net_force: number;
    dead_load: number;
    roof_allocation: number;
    num_panels: number;
    total_pnom: number;
  };
  task?: {
    status: string;
    started_at: string;
  };
  task_metrics?: {
    task_run_success: boolean;
    pv_design_available: boolean;
    start_time: string;
    run_time: number;
    inputs: {
      options: { [key: string]: string };
    };
  };
  debug?: {
    [key: string]: {
      num_panels_before_object_intersection: number;
      num_panels_after_object_intersection: number;
      building_with_objects_image: string;
      building_with_panels_with_objects_image: string;
    };
  };
  geojson?: {
    panels_aligned: string;
    roof_objects_autodetected: string;
  };
  custom_objects?: (Feature<Polygon> | Feature<LineString>)[];
}

async function requestPvSystemDesign(
  siteData: SiteData,
  panel: Panel,
  buildings: Building[],
  options: Record<string, unknown>,
  customObjects:
    | (Feature<Polygon> | Feature<LineString>)[]
    | undefined = undefined,
  enableTestModePVARequests: boolean
): Promise<{ data: PvaData }> {
  const data = {
    site: siteData,
    panel,
    buildings,
    custom_objects: customObjects,
    options,
  };

  const pvaUrl = enableTestModePVARequests
    ? "https://pva-e2e.com/"
    : getPVAUrl();
  const url = `${pvaUrl}/pv-design?siteid=${siteData.id}`;
  return await axios.post(url, data, { timeout: 30_000 });
}

export const usePvSystemDesign = (
  siteData: SiteData,
  panel: Panel | undefined,
  buildings: Building[],
  autoRefresh: boolean,
  enableTestModePVARequests: boolean
): {
  query: UseQueryResult<{ data: PvaData }>;
  requestPvSystemDesign: typeof requestPvSystemDesign;
} => {
  return {
    query: useQuery(
      ["pv_system_design", siteData?.id, panel, autoRefresh, buildings],
      async () => {
        return await requestPvSystemDesign(
          siteData!,
          panel!,
          buildings,
          {},
          undefined,
          enableTestModePVARequests
        );
      },
      {
        enabled: !!siteData && !!panel && !!buildings,
        refetchInterval: autoRefresh ? 30_000 : false,
        refetchOnWindowFocus: autoRefresh,
      }
    ),
    requestPvSystemDesign,
  };
};

export const usePresignedUrlData = <T>(
  siteId: string,
  urlType: string,
  url: string | undefined
): UseQueryResult<T> => {
  return useQuery(
    [siteId, urlType],
    async (): Promise<T> => {
      const response = await axios.get(url!, { timeout: 30_000 });
      return await response.data;
    },
    {
      enabled: !!url,
    }
  );
};

// ************************************************
//       Deeds - Ownership of land
// ************************************************

async function getTitlesByBounds(
  north: number,
  south: number,
  west: number,
  east: number
): Promise<Record<string, any>> {
  const res = await axios.get(
    `${API_URL}/land_registrations/bounds_postgis/${north}/${south}/${west}/${east}`
  );
  const hashMap: Record<string, any> = {};
  res.data.land_registrations.forEach((building: any) => {
    building.isTitleDeed = true;
    const key = hash(building.geometry.coordinates);
    hashMap[key] = { ...building };
  });
  return hashMap;
}

export const useDeedsByBounds = (
  mapBounds: any
): UseQueryResult<Record<string, any>> => {
  const { north, south, west, east } = getBounds(mapBounds);

  const data = useQuery(
    ["deeds_by_bounds", north, south, west, east],
    async () => {
      return await getTitlesByBounds(north, south, west, east);
    },
    queryOptions({ enabled: !!mapBounds })
  );
  return data;
};

async function getGhiByLatLng(
  latitude: number,
  longitude: number
): Promise<any> {
  const res = await axios.get(
    `https://aqodrf0s5k.execute-api.eu-west-1.amazonaws.com/energy/ghi/latlon/${latitude}/${longitude}`
  );
  return res;
}

export const useGhiByLatLng = (
  lat: number,
  lng: number
): UseQueryResult<any> => {
  const data = useQuery(
    ["ghi_by_latlng", lat, lng],
    async () => {
      return await getGhiByLatLng(lat, lng);
    },
    queryOptions({ enabled: !!lat && !!lng })
  );
  return data;
};
