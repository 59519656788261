export const limitDecimalPlaces = (value, limit) => {
  // We need this method to allow string with fewer than two decimal places e.g. "20"
  // to be entered without being converted to "20.00"
  const originalString = value.toString();
  const twoDecimalPlaceString = parseFloat(value).toFixed(limit);
  if (twoDecimalPlaceString.length < originalString.length) {
    return twoDecimalPlaceString;
  }
  return originalString;
};

export const epochSecondsToDate = (epochSeconds) => {
  if (!epochSeconds) return "";
  const date = new Date(epochSeconds * 1000);
  return date.toISOString().split("T")[0];
};

export const currencyValue = (value, currencyCode) => {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

export const numString = (value, options) => {
  const { minimumFractionDigits } = options
    ? options
    : { minimumFractionDigits: undefined };
  return new Intl.NumberFormat("en-GB", {
    style: "decimal",
    minimumFractionDigits,
    maximumFractionDigits: 2,
  }).format(value);
};

export const numString2dp = (value) => {
  return numString(value, { minimumFractionDigits: 2 });
};

export const handleCompareNumberWithString = (objValue, othValue) => {
  if (typeof objValue === "number" && typeof othValue === "string") {
    return objValue === parseFloat(othValue);
  }
  if (typeof objValue === "string" && typeof othValue === "number") {
    return parseFloat(objValue) === othValue;
  }
  // Return undefined to let lodash handle other comparisons
  return undefined;
};
