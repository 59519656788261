import { generateId, useSite } from "@inrange/building-manager-api-client";
import { Organisation } from "@inrange/building-manager-api-client/models-organisation";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Loading from "../Loading";
import { orgTypeMap } from "./org-constants";

const OrgSettingsForm = ({
  organisation,
  handleSubmit,
  isDisableSubmit,
}: {
  organisation?: Organisation;
  handleSubmit: (payload: any) => void;
  isDisableSubmit: boolean;
}) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [orgName, setOrgName] = useState(organisation?.name ?? "");
  const [orgType, setOrgType] = useState(organisation?.orgType ?? "Tenant");
  const [buildingProfile, setBuildingProfile] = useState<string>(
    organisation?.buildingProfile ?? ""
  );
  // Tenant fields
  const [maxTenantTariff, setMaxTenantTariff] = useState(
    organisation?.maxTenantTariff ?? ""
  );
  const [maxNetworkImportTariff, setMaxNetworkImportTariff] = useState(
    organisation?.maxNetworkImportTariff ?? ""
  );
  const [defaultMarketTariff, setDefaultMarketTariff] = useState(
    organisation?.defaultMarketTariff ?? ""
  );
  // Landlord fields
  const [minIRR, setMinIRR] = useState<number | string>(
    organisation?.minIRR ?? ""
  );

  const { fetchSiteValues } = useSite({
    enableNonEssentialQueries: true,
    app: "admin",
  });

  const params = new URLSearchParams(window.location.search);
  const testCreateTemporaryOrg =
    params.get("testCreateTemporaryOrg") === "true";

  const handleFormSubmit = () => {
    const payload = {
      ...(organisation === undefined && { id: generateId() }),
      name: orgName,
      buildingProfile,
      ...orgTypeMap[orgType],
      ...(orgTypeMap[orgType].orgType === "Tenant" && {
        maxTenantTariff,
        maxNetworkImportTariff,
        defaultMarketTariff,
      }),
      ...(orgTypeMap[orgType].orgType === "Landlord" && { minIRR }),
      isTemporary: testCreateTemporaryOrg,
    };
    handleSubmit(payload);
    setHasUnsavedChanges(false);
  };

  const disableSubmit = isDisableSubmit || orgName.length < 1;

  if (fetchSiteValues.isError) {
    return <div>Error loading site values.</div>;
  }

  if (fetchSiteValues.isLoading) {
    return <Loading label="Loading site values data..." />;
  }

  if (orgTypeMap[orgType].orgType === "Landlord") {
    return (
      <>
        <Row>
          {organisation === undefined && (
            <Col sm={6}>
              <Form.Group className="mb-3" controlId="orgName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={orgName}
                  onChange={(e) => {
                    setHasUnsavedChanges(true);
                    setOrgName(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>
          )}
          <Col sm={3}>
            <Form.Group className="mb-3" controlId="orgType">
              <Form.Label>Organisation type</Form.Label>
              <Form.Select
                value={orgType}
                onChange={(e) => {
                  setHasUnsavedChanges(true);
                  setOrgType(e.target.value);
                }}
              >
                <option hidden disabled value="">
                  Select Type
                </option>
                {Object.keys(orgTypeMap).map((key) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group className="mb-3" controlId="maxNetworkImportTariff">
              <Form.Label>Default building profile</Form.Label>
              <Form.Select
                value={buildingProfile ?? ""}
                onChange={(e) => {
                  setHasUnsavedChanges(true);
                  setBuildingProfile(e.target.value);
                  setMaxNetworkImportTariff(
                    fetchSiteValues.data!.options.buildingProfiles[
                      e.target.value
                    ]?.maxNetworkImportTariff ?? ""
                  );
                  setMaxTenantTariff(
                    fetchSiteValues.data!.options.buildingProfiles[
                      e.target.value
                    ]?.maxTenantTariff ?? ""
                  );
                  setDefaultMarketTariff(
                    fetchSiteValues.data!.options.buildingProfiles[
                      e.target.value
                    ]?.defaultMarketTariff ?? ""
                  );
                }}
              >
                <option value="">Select profile</option>
                {Object.entries(
                  fetchSiteValues.data!.options.buildingProfiles
                ).map(([profileValue, profileData]) => (
                  <option key={profileValue} value={profileValue}>
                    {profileData.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <Form.Group className="mb-3" controlId="minIRR">
              <Form.Label>Minimum target IRR</Form.Label>
              <Form.Control
                type="text"
                value={minIRR}
                onChange={(e) => {
                  setHasUnsavedChanges(true);
                  setMinIRR(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={2}>
            <div style={{ display: "flex", gap: "24px" }}>
              <Button
                variant="primary"
                onClick={handleFormSubmit}
                style={{ width: "108px", marginTop: 32 }}
                disabled={disableSubmit || !hasUnsavedChanges}
              >
                {organisation === undefined ? "Create" : "Update"}
              </Button>
            </div>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <Row>
        {organisation === undefined && (
          <Col sm={6}>
            <Form.Group className="mb-3" controlId="orgName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={orgName}
                onChange={(e) => {
                  setHasUnsavedChanges(true);
                  setOrgName(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
        )}
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="orgType">
            <Form.Label>Organisation type</Form.Label>
            <Form.Select
              value={orgType}
              onChange={(e) => {
                setHasUnsavedChanges(true);
                setOrgType(e.target.value);
              }}
            >
              <option hidden disabled value="">
                Select Type
              </option>
              {Object.keys(orgTypeMap).map((key) => (
                <option key={key} value={key}>
                  {key}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="buildingProfile">
            <Form.Label>Default building profile</Form.Label>
            <Form.Select
              value={buildingProfile ?? ""}
              onChange={(e) => {
                setHasUnsavedChanges(true);
                setBuildingProfile(e.target.value);
                setMaxNetworkImportTariff(
                  fetchSiteValues.data!.options.buildingProfiles[e.target.value]
                    ?.maxNetworkImportTariff ?? ""
                );
                setMaxTenantTariff(
                  fetchSiteValues.data!.options.buildingProfiles[e.target.value]
                    ?.maxTenantTariff ?? ""
                );
                setDefaultMarketTariff(
                  fetchSiteValues.data!.options.buildingProfiles[e.target.value]
                    ?.defaultMarketTariff ?? ""
                );
              }}
            >
              <option value="">Select profile</option>
              {Object.entries(
                fetchSiteValues.data!.options.buildingProfiles
              ).map(([profileValue, profileData]) => (
                <option key={profileValue} value={profileValue}>
                  {profileData.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="maxTenantTariff">
            <Form.Label>Max on-site tariff</Form.Label>
            <Form.Control
              type="text"
              value={maxTenantTariff}
              onChange={(e) => {
                setHasUnsavedChanges(true);
                setMaxTenantTariff(e.target.value);
              }}
            />
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="maxNetworkImportTariff">
            <Form.Label>Max import tariff</Form.Label>
            <Form.Control
              type="text"
              value={maxNetworkImportTariff}
              onChange={(e) => {
                setHasUnsavedChanges(true);
                setMaxNetworkImportTariff(e.target.value);
              }}
            />
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="defaultMarketTariff">
            <Form.Label>Default market tariff</Form.Label>
            <Form.Control
              type="text"
              value={defaultMarketTariff}
              onChange={(e) => {
                setHasUnsavedChanges(true);
                setDefaultMarketTariff(e.target.value);
              }}
            />
          </Form.Group>
        </Col>
        <Col sm={2}>
          <div style={{ display: "flex", gap: "24px" }}>
            <Button
              variant="primary"
              onClick={handleFormSubmit}
              style={{ width: "108px", marginTop: 32 }}
              disabled={disableSubmit || !hasUnsavedChanges}
            >
              {organisation === undefined ? "Create" : "Update"}
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default OrgSettingsForm;
