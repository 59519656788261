import {
  getDefaultsPvSystem,
  useSite,
} from "@inrange/building-manager-api-client";
import {
  getBuildingCoordinates,
  Panel,
  Site,
  SiteCalculations,
} from "@inrange/building-manager-api-client/models-site";
import {
  usePresignedUrlData,
  usePvSystemDesign,
} from "@inrange/inrange-data-api-client";
import { Feature, LineString, Polygon } from "geojson";
import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useQueryClient } from "react-query";
import { v4 } from "uuid";
import Loading from "../../../Loading";
import PVCustomObjectsModal from "../PVCustomObjects/PVCustomObjectsModal";
import PvDebugData from "./PvDebugData";
import PvDesignControls from "./PvDesignControls";
import PvMap from "./PvMap";
import PvSystemDescription from "./PvSystemDescription";
import PvSystemSelectionControls from "./PvSystemSelectionControls";
import PvaTaskStatus from "./PvaTaskStatus";

export type ObjectGeometry = LineString | Polygon;

interface PvSystemProps {
  site: Site;
  setPvDesign: (
    pvPanelName: string,
    installedCapacity: number,
    pvSystem: any,
    currencyCode: string
  ) => void;
  deselectPvDesign: () => void;
  setPvDesignDifference: (difference: boolean) => void;
  userEmail: string;
  siteCalculations: SiteCalculations;
}

const PvSystem: React.FC<PvSystemProps> = ({
  site,
  setPvDesign,
  deselectPvDesign,
  setPvDesignDifference,
  userEmail,
  siteCalculations,
}) => {
  const [showDebugData, setShowDebugData] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [timeSinceRefresh, setTimeSinceRefresh] = useState(0);
  const [pvPanelNameSelected, setPvPanelNameSelected] = useState(
    site.pvPanelName
  );
  const [showCustomObjectsModal, setShowCustomObjectsModal] = useState(false);
  const [customObjects, setCustomObjects] = useState<
    (Feature<Polygon> | Feature<LineString>)[] | undefined
  >(undefined);

  // Test mode handling
  const testMode = !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE;
  const enableTestModePVARequests = Boolean(
    new URL(document.location.toString()).searchParams.get(
      "enableTestModePVARequests"
    )
  );
  const disablePVARequests = testMode && !enableTestModePVARequests;

  // Load PV Panel data from site values
  const { fetchSiteValues } = useSite({
    app: "admin",
    enableNonEssentialQueries: true,
  });
  const pvPanel = fetchSiteValues.data!.options.pvPanels[
    pvPanelNameSelected
  ] as Panel;

  // In test mode we don't invoke the PV Design API endpoint unless we are
  // running the custom pv object test
  // We do this by setting the panel to undefined, which disables the query
  const {
    query: {
      data: pvaAxiosResponse,
      isFetching: isPvaDesignFetching,
      isError: isPvaDesignError,
      isSuccess: isPvaDesignSuccess,
      dataUpdatedAt,
    },
    requestPvSystemDesign,
  } = usePvSystemDesign(
    { id: site.id, name: site.name, user: userEmail },
    disablePVARequests ? undefined : pvPanel,
    site?.buildings,
    autoRefresh,
    enableTestModePVARequests
  );
  const pvaData = pvaAxiosResponse?.data;
  const autoDetectedObjectsQuery = usePresignedUrlData<string[]>(
    site.id,
    "autoDetectedObjectsUrl",
    pvaData?.custom_objects
      ? undefined
      : pvaData?.geojson?.roof_objects_autodetected
  );

  // Show loading spinner when fetching data
  useEffect(() => {
    setShowLoading(isPvaDesignFetching);
  }, [isPvaDesignFetching]);

  // Update the time since the last refresh
  const dataUpdatedAtRef = useRef(0);
  dataUpdatedAtRef.current = dataUpdatedAt;
  useEffect(() => {
    // Return early if there's an outstanding request, or we don't have loaded data
    if (isPvaDesignFetching || !pvaData) {
      return;
    }

    // Toggle auto-refresh if there is/isn't a task
    if (pvaData.task && !autoRefresh) {
      setAutoRefresh(true);
    } else if (!pvaData.task && autoRefresh) {
      setAutoRefresh(false);
    }

    // Return early if the custom modal is open - we don't want to update the time since refresh while the modal is open
    if (showCustomObjectsModal) {
      return;
    }

    // Schedule a timer to update the time since refresh
    let intervalId: NodeJS.Timeout | undefined;
    if (autoRefresh) {
      intervalId = setInterval(() => {
        setTimeSinceRefresh(() => {
          const secondsSinceUpdate =
            (Date.now() - new Date(dataUpdatedAtRef.current).getTime()) / 1000;
          return Math.round(secondsSinceUpdate);
        });
      }, 100); // Run this every 100 ms
    } else {
      setTimeSinceRefresh(0);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isPvaDesignFetching, pvaData, autoRefresh, showCustomObjectsModal]);

  // Handle populating custom objects from the PVA response
  useEffect(() => {
    // Don't make changes to custom objects if the modal is open
    if (showCustomObjectsModal) {
      return;
    }
    // Return early if there's an outstanding request, or we don't have loaded data
    if (isPvaDesignFetching || !isPvaDesignSuccess || !pvaData) {
      return;
    }
    if (pvaData.custom_objects) {
      // We have custom objects, use these
      const customObjectsWithId = Object.values(pvaData.custom_objects).map(
        (obj) => {
          if (!obj.properties) {
            obj.properties = {};
          }
          obj.properties.id = v4();
          if (!obj.properties.type) {
            // Untagged objects are of type "other"
            obj.properties.type = "other";
          }
          if (!obj.properties.source) {
            // Untagged objects were created manually
            obj.properties.source = "manual";
          }
          return obj;
        }
      );
      setCustomObjects(customObjectsWithId);
    } else if (autoDetectedObjectsQuery.isSuccess) {
      // We don't have custom objects, and we do have autodetected objects, so use those
      const autoDetectedObjects = autoDetectedObjectsQuery.data;
      setCustomObjects(
        autoDetectedObjects.flat().map((autoDetectedObjectStr) => {
          const autoDetectedObject = JSON.parse(autoDetectedObjectStr);
          const geometry = autoDetectedObject as Polygon | LineString;
          return {
            type: "Feature",
            geometry: geometry,
            properties: {
              id: v4(),
              type: "autodetected",
              source: "autodetected",
            },
          } as Feature<Polygon> | Feature<LineString>;
        })
      );
    }
  }, [
    autoDetectedObjectsQuery.data,
    autoDetectedObjectsQuery.isSuccess,
    isPvaDesignFetching,
    isPvaDesignSuccess,
    pvaData,
    showCustomObjectsModal,
  ]);

  // Detect whether current PVA design is already applied
  const panelNameDifferent = pvPanelNameSelected !== site.pvPanelName;
  const pvaInstalledCapacity = Number(
    ((pvaData?.site_metrics?.total_pnom || 0) / 1000).toFixed(3)
  );
  const installedCapacityDifferent =
    pvaInstalledCapacity !== Number(site.installedCapacity);
  const pvSystem = getDefaultsPvSystem(
    fetchSiteValues.data!.options.pvSystems,
    site.currencyCode,
    site.pvInverterBrand,
    pvPanelNameSelected
  );
  const lossFactorDifference = !_.isEqual(
    site.generationLossFactors,
    pvSystem.generationLossFactors
  );
  const designSelected = !!site.pvDesignSystemLastUpdatedAt;
  const anyDifference =
    !designSelected ||
    panelNameDifferent ||
    installedCapacityDifferent ||
    lossFactorDifference;
  useEffect(() => {
    if (pvaInstalledCapacity > 0) {
      setPvDesignDifference(anyDifference);
    } else {
      setPvDesignDifference(false);
    }
  }, [anyDifference, setPvDesignDifference, pvaInstalledCapacity]);

  // Aligning buildings using PVA align_shifts
  const alignedBuildings = useMemo(() => {
    if (!site) {
      return [];
    }
    if (!pvaData) {
      return site.buildings;
    }
    return site.buildings.map((building) => {
      if (!pvaData[building.id]?.align_shift) {
        return building;
      }
      const buildingShift: [number, number] | undefined =
        pvaData[building.id]?.align_shift;
      if (!buildingShift) {
        return building;
      }
      const coordinates = getBuildingCoordinates(building);
      const shiftedCoordinates = coordinates[0].map((coord: number[]) => {
        return [coord[0] + buildingShift[0], coord[1] + buildingShift[1]];
      });
      return {
        ...building,
        geometry: { ...building.geometry, coordinates: [shiftedCoordinates] },
      };
    });
  }, [site, pvaData]);

  // Handling for rebuilding the design
  const queryClient = useQueryClient();
  const rebuildDesign = async ({
    rebuildDebugImages,
    forceRerun,
    forceRoofFetch,
    disableRoofObjectIntersection,
    customObjects = undefined,
  }: {
    rebuildDebugImages?: boolean;
    forceRerun?: boolean;
    forceRoofFetch?: boolean;
    disableRoofObjectIntersection?: boolean;
    customObjects?: (Feature<Polygon> | Feature<LineString>)[];
  }) => {
    if (disablePVARequests) {
      return;
    }

    setShowLoading(true);
    await requestPvSystemDesign(
      { id: site.id, name: site.name, user: userEmail },
      pvPanel,
      site?.buildings,
      {
        ...(rebuildDebugImages && { rebuild_debug_images: rebuildDebugImages }),
        ...(forceRerun && { force_rerun: forceRerun }),
        ...(forceRoofFetch && { force_roof_fetch: forceRoofFetch }),
        ...(disableRoofObjectIntersection && {
          disable_roof_object_intersection: disableRoofObjectIntersection,
        }),
      },
      customObjects,
      enableTestModePVARequests
    );
    // Once the new run has been triggered, clear the cache so that we show the details of
    // the newly launched task
    queryClient.invalidateQueries(["pv_system_design", site?.id, pvPanel]);
  };

  return (
    <Card body className="mt-2">
      <Form.Label>
        <strong>Auto-generated PV system</strong>
      </Form.Label>
      <PvSystemSelectionControls
        anyDifference={anyDifference}
        designSelected={designSelected}
        selectPvDesign={() =>
          setPvDesign(
            pvPanelNameSelected,
            pvaInstalledCapacity,
            pvSystem,
            siteCalculations.currencyCode
          )
        }
        disableSelection={
          !site?.id || pvaInstalledCapacity === 0 || !anyDifference
        }
        deselectPvDesign={deselectPvDesign}
      />
      <PvSystemDescription
        pvPanelNameSelected={pvPanelNameSelected}
        setPvPanelNameSelected={setPvPanelNameSelected}
        fetchSiteValues={fetchSiteValues}
        pvPanel={pvPanel}
        pvaData={pvaData}
        pvaInstalledCapacity={pvaInstalledCapacity}
      />
      {pvaData?.task && (
        <PvaTaskStatus
          pvaData={pvaData}
          autoRefresh={autoRefresh}
          timeSinceRefresh={timeSinceRefresh}
        />
      )}
      {showLoading && <Loading height="500px" label="Loading PV Design" />}
      {isPvaDesignError && <div>Error loading PV system design.</div>}
      {!showLoading && !pvaData?.task && (
        <>
          <Form.Label>
            <strong>Site Map:</strong>
          </Form.Label>
          <div>
            <PvMap
              siteId={site.id}
              siteCenter={[site.latitude, site.longitude]}
              buildings={alignedBuildings}
              customObjects={customObjects || []}
              panelsUrl={pvaData?.geojson?.panels_aligned}
              usePresignedUrlData={usePresignedUrlData}
            />
          </div>
        </>
      )}
      {isPvaDesignSuccess && (
        <>
          <PvDesignControls
            setShowDebugData={setShowDebugData}
            showDebugData={showDebugData}
            setShowCustomObjectsModal={setShowCustomObjectsModal}
            buildings={site.buildings}
            customObjects={customObjects || []}
          />
          {showDebugData && (
            <PvDebugData pvaData={pvaData} rebuildDesign={rebuildDesign} />
          )}
        </>
      )}
      {showCustomObjectsModal && (
        <PVCustomObjectsModal
          buildings={alignedBuildings}
          siteCenter={[site.latitude, site.longitude]}
          customObjects={customObjects}
          setCustomObjects={setCustomObjects}
          setShowModal={setShowCustomObjectsModal}
          triggerCustomObjectsRebuild={(customObjects) => {
            rebuildDesign({
              forceRerun: true,
              forceRoofFetch: false,
              rebuildDebugImages: false,
              disableRoofObjectIntersection: false,
              customObjects,
            });
          }}
        />
      )}
    </Card>
  );
};

export default PvSystem;
