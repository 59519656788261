import { sortBy, sortByAdditional } from "@inrange/calculations/utils.ts";
import { Icons } from "@inrange/theme-components/icons";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import getCustomerAppDomain from "../../../../utils/getCustomerAppDomain";
import { SITE_ORG_LABELS } from "../enums";

const BelongsTo = ({ siteID, ownerships, onOwnerRemoved }) => {
  const [siteOwnerships, setSiteOwnershps] = useState(ownerships);

  const removeOwnership = (siteOrg) => {
    onOwnerRemoved(siteOrg);
    setSiteOwnershps(
      siteOwnerships.filter(
        (existingSiteOrg) => existingSiteOrg.orgID !== siteOrg.orgID
      )
    );
  };
  return (
    <Row style={{ paddingLeft: "12px" }}>
      <Form.Text muted>Belongs to:</Form.Text>
      <Table
        borderless
        style={{
          borderCollapse: "separate",
          borderSpacing: "0px 8px",
          width: "fit-content",
        }}
      >
        <tbody>
          {siteOwnerships
            .sort(
              sortByAdditional(
                (siteOrg) => siteOrg.name,
                sortBy((siteOrg) => siteOrg.ownership)
              )
            )
            .map((siteOrg, i) => (
              <tr key={i}>
                <td>
                  <strong style={{ padding: 0 }}>
                    <a
                      href={`/organisation/${siteOrg.orgID}/edit`}
                      style={{ color: "black", textDecoration: "none" }}
                      data-testid={`belongs-to-org-link-${i}`}
                    >
                      {siteOrg.name}
                    </a>
                  </strong>
                </td>
                <td>
                  <div>{SITE_ORG_LABELS[siteOrg.ownership]}</div>
                </td>
                <td>
                  <a
                    href={`${getCustomerAppDomain()}/org/${
                      siteOrg.orgID
                    }/site/${siteID}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      paddingLeft: "12px",
                      paddingRight: "20px",
                      color: "#0d6efd",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    Public site
                    <img
                      alt="view"
                      src={Icons.shareBlue}
                      style={{
                        width: "17px",
                        height: "17px",
                        marginLeft: "8px",
                        marginBottom: "2px",
                        cursor: "pointer",
                      }}
                    ></img>
                  </a>
                </td>
                <td style={{ borderLeft: "2px solid #adb5bd" }}>
                  <div
                    onClick={() => removeOwnership(siteOrg)}
                    style={{
                      paddingLeft: "20px",
                      paddingRight: "12px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="text-danger">Remove organisation</div>
                    <img
                      alt="remove"
                      src={Icons.trashcanRed}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "6px",
                        cursor: "pointer",
                      }}
                    ></img>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Row>
  );
};

export default BelongsTo;
