import {
  PartialSiteAllowStringValues,
  SiteAllowStringValues,
} from "@inrange/building-manager-api-client/models-site";
import {
  SiteCalculationsContext,
  useContextTS,
} from "@inrange/shared-components";
import { currencySymbol } from "@inrange/theme-components/formatting";
import { useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";

const twoDecimalPlaceString = (cost: string | number | undefined): string => {
  if (cost === undefined) {
    return "";
  }
  if (typeof cost === "string") {
    // String values have been entered locally, so don't format these
    return cost;
  }
  return parseFloat(cost.toString()).toFixed(2);
};

const Cost = ({
  site,
  setSite,
  isBulkEdit,
}: {
  site: SiteAllowStringValues;
  setSite: (site: PartialSiteAllowStringValues) => void;
  isBulkEdit?: boolean;
}) => {
  const { errors, siteCalculations } = useContextTS(SiteCalculationsContext);
  const [displayCostInflationRates, setDisplayCostInflationRates] =
    useState(false);

  const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    e.target.value = isNaN(Number(value))
      ? value
      : twoDecimalPlaceString(value);
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;
    const [costInputName, costInputKey] = id.split(".");
    setSite({
      [costInputName]: {
        ...site[costInputName],
        [costInputKey]: value,
      },
    });
  };

  const range = (start: number, end: number): number[] => {
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  const costInflationRatesView = isBulkEdit ? undefined : (
    <>
      <Row>
        {range(2, 25).map((year) => {
          return (
            <Col sm={3} key={year}>
              <Form.Group
                className="mb-3"
                controlId={`costInflationRates${year - 1}`}
              >
                <Form.Label>Year {year} inflation (%)</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={
                    site.costInputsEconomic.costInflationRates[year - 1]
                  }
                  onChange={(e) => {
                    const newCostInflationRates =
                      site.costInputsEconomic.costInflationRates;
                    newCostInflationRates[year - 1] = e.target.value;
                    setSite({
                      costInputsEconomic: {
                        ...site.costInputsEconomic,
                        costInflationRates: newCostInflationRates,
                      },
                    });
                  }}
                  isInvalid={
                    !!errors[
                      `costInputsEconomic.costInflationRates.${year - 1}`
                    ]
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors[`costInputsEconomic.costInflationRates.${year - 1}`]}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          );
        })}
      </Row>
    </>
  );

  const showCostInflationRatesLink = (
    <Col style={{ paddingTop: "40px" }}>
      <a
        onClick={() => setDisplayCostInflationRates(true)}
        style={{
          cursor: "pointer",
          color: "rgb(13, 110, 253)",
        }}
      >
        Show cost inflation rates
      </a>
    </Col>
  );

  return (
    <Card body className="mt-2">
      <Form.Label>
        <strong>Costs and rates</strong>
      </Form.Label>
      <Row>
        <Col sm={isBulkEdit ? 4 : 3}>
          <Form.Group
            className="mb-3"
            controlId="costInputsPv.hardwareCostPerKWp"
          >
            <Form.Label>Hardware cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={twoDecimalPlaceString(
                site.costInputsPv.hardwareCostPerKWp
              )}
              onChange={onChangeHandler}
              onBlur={onBlurHandler}
              isInvalid={!!errors["costInputsPv.hardwareCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.hardwareCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={isBulkEdit ? 4 : 3}>
          <Form.Group
            className="mb-3"
            controlId="costInputsPv.installationCostPerKWp"
          >
            <Form.Label>Install cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={twoDecimalPlaceString(
                site.costInputsPv.installationCostPerKWp
              )}
              onChange={onChangeHandler}
              onBlur={onBlurHandler}
              isInvalid={!!errors["costInputsPv.installationCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.installationCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        {!isBulkEdit && (
          <Col sm={3}>
            <Form.Group className="mb-3" controlId="initialCostPerKWp">
              <Form.Label>Upfront cost per kWp</Form.Label>
              <Form.Control
                disabled
                type="text"
                value={twoDecimalPlaceString(
                  siteCalculations.projectCosts?.initialCostPerKWp || 0
                )}
                onBlur={onBlurHandler}
              />
            </Form.Group>
          </Col>
        )}
        <Col sm={isBulkEdit ? 4 : 3}>
          <Form.Group
            className="mb-3"
            controlId="costInputsPv.enablingWorksCosts"
          >
            <Form.Label>
              Enabling works cost
              {!isBulkEdit && (
                <> ({currencySymbol(siteCalculations.currencyCode)})</>
              )}
            </Form.Label>
            <Form.Control
              type="text"
              value={twoDecimalPlaceString(
                site.costInputsPv.enablingWorksCosts
              )}
              onChange={onChangeHandler}
              onBlur={onBlurHandler}
              isInvalid={!!errors["costInputsPv.enablingWorksCosts"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.enablingWorksCosts"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={isBulkEdit ? 4 : 3}>
          <Form.Group
            className="mb-3"
            controlId="costInputsPv.maintenanceCostPerKWp"
          >
            <Form.Label>O&M cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={twoDecimalPlaceString(
                site.costInputsPv.maintenanceCostPerKWp
              )}
              onChange={onChangeHandler}
              isInvalid={!!errors["costInputsPv.maintenanceCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.maintenanceCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={isBulkEdit ? 4 : 3}>
          <Form.Group
            className="mb-3"
            controlId="costInputsPv.insuranceCostPerKWp"
          >
            <Form.Label>Insurance cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={twoDecimalPlaceString(
                site.costInputsPv.insuranceCostPerKWp
              )}
              onChange={onChangeHandler}
              isInvalid={!!errors["costInputsPv.insuranceCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.insuranceCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        {!isBulkEdit && (
          <>
            <Col sm={3}>
              <Form.Group
                className="mb-3"
                controlId="costInputsPv.replacementCapexYear"
              >
                <Form.Label>PV replacement (years)</Form.Label>
                <Form.Control
                  type="text"
                  value={site.costInputsPv.replacementCapexYear}
                  onChange={onChangeHandler}
                  onBlur={onBlurHandler}
                  isInvalid={!!errors["costInputsPv.replacementCapexYear"]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors["costInputsPv.replacementCapexYear"]}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group
                className="mb-3"
                controlId="costInputsPv.replacementCapexRate"
              >
                <Form.Label>PV replacement rate (%)</Form.Label>
                <Form.Control
                  type="text"
                  value={site.costInputsPv.replacementCapexRate}
                  onChange={onChangeHandler}
                  onBlur={onBlurHandler}
                  isInvalid={!!errors["costInputsPv.replacementCapexRate"]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors["costInputsPv.replacementCapexRate"]}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </>
        )}
      </Row>
      {!isBulkEdit && (
        <>
          <Row>
            <Col sm={3}>
              <Form.Group className="mb-3" controlId="energyPriceInflationRate">
                <Form.Label>Energy inflation (%)</Form.Label>
                <Form.Control
                  type="text"
                  value={site.energyPriceInflationRate}
                  onChange={(e) =>
                    setSite({ energyPriceInflationRate: e.target.value })
                  }
                  onBlur={onBlurHandler}
                  isInvalid={!!errors.energyPriceInflationRate}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.energyPriceInflationRate}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            {!displayCostInflationRates && showCostInflationRatesLink}
          </Row>
          {displayCostInflationRates && costInflationRatesView}
        </>
      )}
    </Card>
  );
};

export default Cost;
