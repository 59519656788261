import { getMapboxUrl } from "@inrange/building-manager-api-client";
import { Site } from "@inrange/building-manager-api-client/models-site";
import { Icon, latLngBounds } from "leaflet";
import "leaflet/dist/leaflet.css";
import { useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import markerSVG from "../home.svg";

const MapOfSites: React.FC<{ sites: Site[] }> = ({ sites }) => {
  const [_map, setMap] = useState<any>();
  const [tileLoadingLabel, setTileLoadingLabel] = useState("tile-loading");
  const mapTheme = "streets-v11";
  const mapbox_url = getMapboxUrl(mapTheme);

  const defaultCenter: [number, number] = [
    51.50032365386702, -0.12426640270284971,
  ];
  const mapCenter: [number, number] =
    sites?.length > 0 ? [sites[0].latitude, sites[0].longitude] : defaultCenter;

  const myIcon = new Icon({
    iconUrl: markerSVG,
    iconSize: [16, 16],
  });

  const popupStyle1 = {
    marginTop: 10,
    color: "gray",
    fontSize: 10,
  };

  const bounds = latLngBounds([mapCenter]);

  const siteMarkers = sites?.map((site, ii) => {
    bounds.extend([site.latitude, site.longitude]);
    return (
      <Marker position={[site.latitude, site.longitude]} key={ii} icon={myIcon}>
        <Popup>
          <div style={popupStyle1}>Name</div>
          <div>
            <a href={`/site/${site.id}/edit`} target="_blank" rel="noreferrer">
              {site.name}
            </a>
          </div>
          <div style={popupStyle1}>Belongs To</div>
          <div>
            {site.siteOwnerships?.map((siteOrg) => (
              <div key={siteOrg.orgID}>
                {siteOrg.name}: {siteOrg.ownership}
              </div>
            ))}
          </div>
          <div style={popupStyle1}>Address</div>
          <div>{site.address}</div>
        </Popup>
      </Marker>
    );
  });

  return (
    <div data-testid={tileLoadingLabel}>
      <MapContainer
        style={{ width: "100%", height: "800px", margin: "0 0 10px" }}
        scrollWheelZoom={false}
        ref={setMap}
        bounds={bounds}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url={mapbox_url}
          eventHandlers={{
            loading: () => setTileLoadingLabel("tile-loading"),
            load: () => setTileLoadingLabel("tile-loaded"),
          }}
        />
        {siteMarkers}
      </MapContainer>
    </div>
  );
};

export default MapOfSites;
